import { useWindowDimension } from 'hooks/useWindowDimension'
import React from 'react'
import videoBgMobile from '../assets/videos/hello-dub-chico.mp4'
import videoBgLaptop from '../assets/videos/hello-dub-grande.mp4'
import { ReactComponent as Logo } from '../assets/images/logo-slim.svg'

export const About = () => {
  const [width] = useWindowDimension()
  return (
    <div
      className=' flex flex-col justify-center items-center mt-10 w-[100vw] h-[100vh] z-10 section scroll-snap-start'
      id='about-page'
    >
      {/* {width <= 768 ? (
        <video
          src={videoBgMobile}
          autoPlay
          loop
          muted
          className='z-1 absolute w-[100%] h-[100vh] left-0 object-cover'
        />
      ) : (
        <video
          src={videoBgLaptop}
          autoPlay
          loop
          muted
          className='z-1 absolute w-[100%] h-[100vh] left-0 object-cover'
        /> */}
      )}
      <div className='flex flex-col items-center justify-star relative text-center m-10 bg-opacity-0 h-[100vh] w-[100vw] mx-auto rounded-xl'>
        <Logo
          style={{ width: '50px', fill: '#fefae0' }}
          className='mt-10 text-center items-center'
          fill='font-color-white'
        />
        <h1 className='text-bold mt-[100px] text-center font-jetbrains text-font-color-white text-3xl'>
          Quienes somos?
        </h1>
        <p className='text-bold leading-8 text-center m-2 justify-center font-jetbrains text-font-color-white text-m mt-10 md:text-xl'>
          Dub Studio es un venue dedicado a mezclar las diferentes vertientes
          del arte. <br />
          <br />
          Creemos que todos tenemos una parte artística y estamos abiertos a
          recibir nuevos talentos y diferentes formas de apreciar al arte.{' '}
          <br />
          <br />
          <br />
          Para lograr esto organizamos eventos con diferentes temáticas.
        </p>
      </div>
    </div>
  )
}
