import './styles/index.scss'
import NavBar from './components/NavBar'
import Home from 'components/Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { About } from 'components/About'
import { loadStripe } from '@stripe/stripe-js'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import SuccessfulPayment from 'components/SuccessfulPayment'
import videoBgMobile from './assets/videos/monos-verde-chico.mp4'
import videoBgLaptop from './assets/videos/hello-dub-grande.mp4'
import Events from 'components/Events'
import { useWindowDimension } from 'hooks/useWindowDimension'
import { OrderProvider } from 'contexts/OrderContext'
import ScrollButton from 'components/ScrollButton'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

function App() {
  const [formData, setFormData] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)

  useEffect(() => {
    // Create a Checkout Session
    fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret)
      })
  }, [formData])
  const [width] = useWindowDimension()
  return (
    <>
      <OrderProvider>
        <Router>
          {width <= 768 ? (
            <video
              className='absolute w-[100%] h-[100vh] top-0 left-0 object-cover'
              playsInline
              autoPlay
              muted
              loop
              style={{ width: '100%', height: 'auto' }}
            >
              <source src={videoBgMobile} type='video/mp4' />
            </video>
          ) : (
            <video
              className='absolute w-[100%] h-[100vh] top-0 left-0 object-cover'
              playsInline
              autoPlay
              muted
              loop
              style={{ width: '100%', height: 'auto' }}
            >
              <source src={videoBgLaptop} type='video/mp4' />
            </video>
          )}

          <div className='w-[100%] h-[100vh] flex flex-col mx-auto items-center position-absolute'>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <Routes>
                <Route
                  path='/'
                  element={
                    <div className='flex flex-col bg-pure-black'>
                      <NavBar />
                      <Home setFormData={setFormData} />
                      <Events />
                      <About />
                      <ScrollButton />
                    </div>
                  }
                ></Route>
                <Route
                  path='/checkout'
                  element={<EmbeddedCheckout className='bg-white z-10' />}
                ></Route>
                <Route
                  path='/successful_payment'
                  element={<SuccessfulPayment />}
                ></Route>
              </Routes>
            </EmbeddedCheckoutProvider>
          </div>
        </Router>
      </OrderProvider>
    </>
  )
}

export default App
