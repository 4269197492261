import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from '../assets/images/logo-slim.svg'
import { ReactComponent as QuestionMarkLogo } from '../assets/images/question-mark-logo.svg'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

const venue = 'DubStudio'

export default function NavBar() {
  const [header, setHeader] = useState('')
  const [blink, setBlink] = useState(true)
  const [nav, setNav] = useState(false)

  useEffect(() => {
    const timeout = setInterval(() => {
      setHeader(venue.slice(0, header.length + 1))
    }, 100)

    return () => clearInterval(timeout)
  }, [header])

  useEffect(() => {
    const interval = setInterval(() => {
      setBlink(false)
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const handleNav = () => {
    setNav(!nav)
  }
  return (
    <div className='mr-2 ml-2 flex flex-col items-center'>
      <div className='w-[96vw] justify z-[1000] md:mx-auto flex justify-between items-center  h-[75px] bg-opacity-85 rounded-lg mt-[4px] absolute'>
        <LinkRouter to='/'>
          <h1
            className={`z-[10000] text-font-color-green mx-4 font-jetbrains font-bold text-4xl text-enter justify-center ${
              blink ? 'blinking-cursor' : ''
            }`}
          >
            {header}
          </h1>
        </LinkRouter>
        <ul className='hidden md:flex'>
          <LinkScroll
            to='events-page'
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className='cursor-pointer p-4 opacity-85'
          >
            <p className='text-font-color-white font-jetbrains font-bold text-xl'>
              Eventos
            </p>
          </LinkScroll>
          <LinkScroll
            to='home-page'
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className='cursor-pointer p-4 opacity-85'
          >
            <p className='text-font-color-white font-jetbrains font-bold text-xl'>
              Drinks
            </p>
          </LinkScroll>
          <LinkScroll
            to='about-page'
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className='z-1000 p-4 relative cursor-pointer'
          >
            <p className='text-font-color-white font-jetbrains font-bold text-xl'>
              About
            </p>
          </LinkScroll>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? (
            <AiOutlineClose style={{ color: 'white' }} size={25} />
          ) : (
            <AiOutlineMenu style={{ color: 'white' }} size={25} />
          )}
        </div>
        <div
          className={
            nav
              ? 'absolute left-0 top-0 w-[60%] h-full border-r border-r-gray-100 ease-in duration-500'
              : 'absolute left-[-100%] top-0 w-[60%] ease-in duration-500'
          }
        >
          <ul className='pt-24 uppercase'>
            <LinkScroll
              to='events-page'
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className='cursor-pointer p-4 opacity-85'
            >
              <p className='border-b border-gray-100 text-font-color-white font-jetbrains font-bold text-xl'>
                Eventos
              </p>
            </LinkScroll>
            <LinkScroll
              to='home-page'
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className='cursor-pointer p-4 opacity-85'
            >
              <p className='border-b border-gray-100 text-font-color-white font-jetbrains font-bold text-xl'>
                Drinks
              </p>
            </LinkScroll>
            <LinkScroll
              to='about-page'
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className='z-1000 p-4 relative cursor-pointer'
            >
              <p className='border-b border-gray-100 text-font-color-white font-jetbrains text-xl'>
                About
              </p>
            </LinkScroll>
          </ul>
        </div>
      </div>
    </div>
  )
}
