import React, { useEffect, useState } from 'react'
import Card from './Card'
import videoBgLaptop from '../assets/videos/monos-verde-grande.mov'
import { useWindowDimension } from 'hooks/useWindowDimension'
import axios from 'axios'
import NewTicketModal from './NewTicketModal'

export default function Events() {
  const [width] = useWindowDimension()
  const [isOpen, setOpen] = useState(false)
  const [remainingTickets, setRemainingTickets] = useState()
  useEffect(() => {
    const handleScroll = () => {
      const homePageDiv = document.getElementById('events-page')
      if (homePageDiv && isOpen) {
        homePageDiv.scrollIntoView({ behavior: 'smooth' })
      }
    }

    if (isOpen) {
      document.body.style.overflow = 'hidden'
      handleScroll()
    } else {
      document.body.style.overflow = 'auto'
    }
    // Cleanup function to restore scrolling when the modal is unmounted
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  useEffect(() => {
    const fetchRemainingTickets = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || ''

        const response = await axios.get(`${apiUrl}/remaining_tickets`)
        console.log(response.data?.remaining_tickets)
        setRemainingTickets(response.data?.remaining_tickets)
      } catch (error) {
        console.log(error)
      }
    }

    fetchRemainingTickets()

    // Cleanup function
    return () => {
      // You can perform cleanup if needed
    }
  }, [])

  const handleClick = () => {
    setOpen(true)
  }
  return (
    <div
      id='events-page'
      name='events-page'
      className='flex flex-col justify-center items-center bg-pure-black mt-10 w-[100vw] h-[100vh] z-10 section scroll-snap-start '
    >
      {width > 768 && (
        <video
          className='z-0 absolute w-[100%] h-[100vh] left-0 object-cover'
          playsInline
          autoPlay
          muted
          loop
          style={{ width: '100%', height: 'auto' }}
        >
          <source src={videoBgLaptop} type='video/mp4' />
        </video>
      )}
      <ul className='flex flex-col items-center z-10 justify-center'>
        <Card
          soldOut={remainingTickets < 1}
          handleClick={handleClick}
          imageSrc={require('../assets/images/choflas-flyer.jpeg')}
          title='Auf Wiedersehen'
          description={`"Auf Wiedersehen" viene del alemán y se traduce a "hasta pronto".\n
          
          Esta fiesta tomará lugar en una casa que vio a los integrantes de Dub Studio nacer y nos queremos despedir de ella de la mejor manera, con un pedón.`}
          price={'$ 100 MXN'}
        />
      </ul>
      <NewTicketModal
        imgSrc={require('../assets/images/choflas-flyer.jpeg')}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      ></NewTicketModal>
    </div>
  )
}
