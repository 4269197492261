import React, { useEffect, useState } from 'react'
import TicketModal from './TicketModal'
import axios from 'axios'

export default function Home({ setFormData }) {
  const [open, setOpen] = useState(false)
  const [remainingTickets, setRemainingTickets] = useState(null)

  useEffect(() => {
    const fetchRemainingTickets = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || ''

        const response = await axios.get(`${apiUrl}/remaining_tickets`)
        console.log(response.data?.remaining_tickets)
        setRemainingTickets(response.data?.remaining_tickets)
      } catch (error) {
        console.log(error)
      }
    }

    fetchRemainingTickets()

    // Cleanup function
    return () => {
      // You can perform cleanup if needed
    }
  }, [])
  useEffect(() => {
    const handleScroll = () => {
      const homePageDiv = document.getElementById('home-page')
      if (homePageDiv && open) {
        homePageDiv.scrollIntoView({ behavior: 'smooth' })
      }
    }

    if (open) {
      document.body.style.overflow = 'hidden'
      handleScroll()
    } else {
      document.body.style.overflow = 'auto'
    }
    // Cleanup function to restore scrolling when the modal is unmounted
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open])
  return (
    <div
      className='flex flex-col justify-center w-[100%] h-[100vh] bg-pure-black'
      id='home-page'
    >
      {/* <div className='z-[0] fixed inset-0 min-h-screen bg-svg-background-layer-robe bg-no-repeat animate-robe bg-center opacity-[50%]' />
      <div className='zbg-opacity-100 fixed inset-0 z-[0] min-h-screen bg-svg-background-layer-eyes bg-center bg-no-repeat animate-eyes ' /> */}
    </div>
  )
}
