import React, { createContext, useState, useContext } from 'react'

// Create a context
const OrderContext = createContext()

// Create a provider component
const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState(null)

  const setOrderInfo = (orderInfo) => {
    setOrder(orderInfo)
  }

  return (
    <OrderContext.Provider value={{ order, setOrderInfo }}>
      {children}
    </OrderContext.Provider>
  )
}

// Custom hook to use the OrderContext
const useOrder = () => {
  const context = useContext(OrderContext)
  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider')
  }
  return context
}

export { OrderProvider, useOrder }
