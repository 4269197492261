import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function SuccessfulPayment() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const sessionId = searchParams.get('session_id')
  const orderId = searchParams.get('order_id')
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }
  return (
    <div className='mt-10 relative flex flex-col p-2 justify-start rounded-r-xl'>
      <div className='bg-secondary-color relative justify-center text-center bg-font-color m-10 bg-opacity-95 h-[70vh] w-[90vw] mx-auto rounded-xl '>
        <h1 className='leading-8 text-center m-2 justify-center font-jetbrains text-font-color text-3xl mt-10 md:text-xl'>
          Gracias por tu compra! 🎉
        </h1>
        <p className='mt-10 text-center font-jetbrains text-font-color text-m justify-center'>
          Tus boletos te llegaran por correo electronico en breve, en caso de no
          recibirlos favor de contactar a support@dubstudiomusic.com
        </p>
        <p className='mt-10 text-center font-jetbrains text-font-color text-m'>
          numero de orden: {orderId}
        </p>
        <button
          onClick={handleClick}
          className='font-jetbrains mt-[15%] w-[100px] h-16 md:w-1/5 md:mt-[80%] xl:mt-[0px] bg-white bg-buttons-color opacity-85 mx-auto flex justify-center items-center rounded-lg px-2 text-font-color-white'
        >
          Home
        </button>
      </div>
    </div>
  )
}
