import styled from 'styled-components'

export const Heading = styled.h1`
  text-align: center;
  color: white;
`

export const Content = styled.div`
  overflow-y: scroll;
  height: 2500px;
`

export const Button = styled.div`
  position: fixed;
  bottom: 100px;

  z-index: 100000000;
  cursor: pointer;
  color: white;

  @media (min-width: 768px) {
    width: 100%;
    left: 96%;
    height: 10px;
    font-size: 2rem;
  }

  @media (max-width: 767px) {
    width: 20%;
    left: 92.5%;
    height: 2px;
    font-size: 1.5rem;
  }
`
