import React from 'react'
import { useForm } from 'react-hook-form'

const FullScreenModal = ({ imgSrc, isOpen, onClose, ticketPrice }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      numberOfTickets: 1,
      name: '',
      email: '',
    },
  })

  const numberOfTickets = watch('numberOfTickets')

  const onSubmit = (data) => {
    console.log(data)

    onClose()
  }

  if (!isOpen) {
    return null
  }
  // Function to close modal if clicking outside of the modal content
  const handleBackdropClick = (e) => {
    if (e.target.id === 'modal-backdrop') {
      onClose()
    }
  }
  return (
    <div
      id='modal-backdrop'
      className='z-[10000] backdrop-blur-md fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75'
      onClick={handleBackdropClick}
    >
      <div
        className='bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:w-full sm:max-w-4xl'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='flex flex-row'>
          <div className='w-1/2'>
            <img
              src={imgSrc}
              alt='Modal Visual'
              className='object-cover h-full w-full'
            />
          </div>
          <div className='bg-pure-black text-font-color-white w-1/2 p-6 space-y-6 overflow-auto text-center'>
            <h2 className='text-3xl font-semibold'>Compra tus Boletos!</h2>
            <form
              className='text-pure-black'
              onSubmit={handleSubmit(onSubmit)}
              className='space-y-4'
            >
              <div>
                <label
                  htmlFor='numberOfTickets'
                  className='block text-sm font-medium text-gray-700'
                >
                  Numero de boletos:
                </label>
                <select
                  {...register('numberOfTickets', { required: true })}
                  className='text-center text-pure-black mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                >
                  {[...Array(10).keys()].map((number) => (
                    <option key={number + 1} value={number + 1}>
                      {number + 1}
                    </option>
                  ))}
                </select>
                {errors.numberOfTickets && (
                  <span className='text-red-500'>
                    Selecciona como mínimo 1 boleto
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-gray-700'
                >
                  Nombre:
                </label>
                <input
                  type='text'
                  {...register('name', { required: true })}
                  className='text-center text-pure-black mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {errors.name && (
                  <span className='text-red-500'>Name is required</span>
                )}
              </div>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  Email:
                </label>
                <input
                  type='email'
                  {...register('email', {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  className='text-center text-pure-black mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {errors.email && (
                  <span className='text-red-500'>
                    La dirección de correo no tiene un formato válido
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor='price'
                  className='block text-sm font-medium text-gray-700'
                >
                  Total:
                </label>
                <input
                  type='text'
                  value={`$ ${numberOfTickets * ticketPrice}`}
                  readOnly
                  className='text-pure-black text-center mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm bg-gray-100'
                />
              </div>
              <button
                type='submit'
                disabled={
                  !numberOfTickets ||
                  !!errors.name ||
                  !!errors.email ||
                  !!errors.numberOfTickets
                }
                className='w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Comprar!
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullScreenModal
