import { useWindowDimension } from 'hooks/useWindowDimension'
import React from 'react'

const Card = ({
  soldOut,
  handleClick,
  className,
  imageSrc,
  title,
  description,
  price,
}) => {
  const TextWithBreaks = ({ text }) => {
    const newText = text.split('\n').map((item, i) => (
      <React.Fragment key={i}>
        {item}
        <br />
      </React.Fragment>
    ))

    return <p>{newText}</p>
  }
  const [width] = useWindowDimension()
  const combinedClassName = `z-10 w-[85%] bg-buttons-color bg-opacity-98 flex bg-white shadow-lg rounded-lg overflow-hidden ${className}`

  return (
    <div className={combinedClassName}>
      <div className='w-1/2'>
        <img
          src={imageSrc}
          alt='Card'
          className='object-cover h-full w-full'
          style={
            width > 768
              ? { width: '900px', height: '476px' }
              : { width: '100%', height: '100%' }
          }
        />
      </div>
      <div className='w-2/3 p-4 flex flex-col items-center justify-between'>
        <div>
          <h1 className='justify-center text-center text-3xl font-bold mb-2 font-jetbrains text-font-color-white'>
            {title}
          </h1>
          <hr className='text-font-color-white' />
          <p className='mt-4 md:mt-8 md:mr-8 md:ml-8 justify-center text-font-color-white font-jetbrains'>
            <TextWithBreaks text={description}></TextWithBreaks>
          </p>
        </div>
        <div className='w-[100%] flex flex-col items-center'>
          <div className='flex justify-between items-center'>
            <h2 className='mt-8 text-m italic font-semibold text-gray-800 text-font-color-white'>
              {price}
            </h2>
            {/* Add any other elements here for additional actions/buttons */}
          </div>
          <button
            onClick={handleClick}
            className={`${
              soldOut ? ' cursor-not-allowed bg-disabled' : ''
            } transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 mt-4 w-[100%] bg-font-color-white text-font-color rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50  text-center font-jetbrains items-center justify-center`}
          >
            <h1 className='text-center font-jetbrains text-2xl ease-in-out duration-500'>
              {soldOut ? 'Sold Out!' : 'Comprar Boletos'}
            </h1>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Card
